<template>
	<v-container fluid>
		<div>
			<v-card class="pa-2" flat>
				<v-card-title>
					<v-row>
						<v-col
							cols="12"
							:sm="
								similarBrand[0] && showCards && !loading
									? 8
									: 12
							"
							:lg="
								similarBrand[0] && showCards && !loading
									? 9
									: 12
							"
							class="d-flex align-center"
						>
							<v-row>
								<v-col
									lg="6"
									cols="12"
									class="pb-0"
									id="tourCustomer"
								>
									<v-autocomplete
										v-model="customer"
										label="Customer"
										:items="customers"
										item-text="display"
										item-value="customer_id"
										dense
										outlined
										:search-input.sync="searchCustomers"
										:no-data-text="noCustomerData"
										@change="updatePlantAndCreditInfo()"
										clearable
										:messages="segment"
									>
									<template v-slot:message="{ message }">
											<span style="color: #000;">
												Segment: {{ message }}
											</span>
											<!-- <span v-if="searchingCredit">
												<strong>
													Credit Available:
												</strong>
												<span>
													&nbsp;
													<v-progress-circular
														:size="10"
														:width="1"
														indeterminate
														color="#13670B"
													/>
												</span>
											</span>
											<template v-else>
												<span
													:style="{
														color:
															message.includes(
																'Blocked'
															) ||
															message.includes(
																'-'
															)
																? 'red'
																: 'green',
													}"
													v-html="message"
												/>
												<span>
													<v-menu
														open-on-hover
														offset-x
													>
														<template
															v-slot:activator="{
																on,
																attrs,
															}"
														>
															<v-icon
																small
																v-bind="attrs"
																v-on="on"
															>
																mdi-help-circle-outline
															</v-icon>
														</template>

														<v-list
															v-if="creditInfo"
														>
															<v-list-item
																two-line
															>
																<v-list-item-content>
																	<v-list-item-title>
																		Last
																		Payment
																		Amount:
																	</v-list-item-title>
																	<v-list-item-subtitle>
																		{{
																			creditInfo.LastPaymentAmount ||
																			0
																		}}
																	</v-list-item-subtitle>
																</v-list-item-content>
															</v-list-item>
															<v-list-item
																two-line
																><v-list-item-content>
																	<v-list-item-title>
																		Last
																		Payment
																		Date:
																	</v-list-item-title>
																	<v-list-item-subtitle>
																		{{
																			creditInfo.LastPaymentDate ||
																			"None"
																		}}
																	</v-list-item-subtitle>
																</v-list-item-content>
															</v-list-item>
														</v-list>
													</v-menu>
												</span>
											</template> -->
										</template>
									</v-autocomplete>
								</v-col>
								<v-col
									lg="6"
									cols="12"
									class="d-flex pb-0"
									id="tourMaterial"
								>
									<v-autocomplete
										v-model="material"
										label="Material"
										:items="materials"
										item-text="display"
										return-object
										dense
										outlined
										cache-items
										clearable
										@change="stockSearch = null"
										:messages="stockSearch"
									>
										<template v-slot:message="{ message }">
											<span v-if="searchingStock">
												<strong>
													Stock Available:
												</strong>
												<span>
													&nbsp;
													<v-progress-circular
														:size="10"
														:width="1"
														indeterminate
														color="#13670B"
													/>
												</span>
											</span>
											<span
												v-else
												:style="{
													color:
														message * 1 > 0
															? 'green'
															: 'red',
												}"
											>
												Stock Available:
												{{ message }}
											</span>
										</template>
									</v-autocomplete>
									<v-tooltip left bottom>
										<template v-slot:activator="{ on }">
											<v-btn
												color="#13670B"
												v-on="on"
												style="
													height: 40px;
													color: white;
												"
												@click="searchStock(material)"
												class="ml-2"
												:loading="
													material?.material_id &&
													(searchingStock ||
														stockLoading ||
														loading)
												"
												:disabled="
													!uom ||
													!plant ||
													!customer ||
													!material?.material_id ||
													searchingStock ||
													stockLoading ||
													loading
												"
												id="tourStockSearch"
											>
												<v-icon>mdi-autorenew</v-icon>
											</v-btn>
										</template>
										<span>Search Stock</span>
									</v-tooltip>
								</v-col>
								<v-col
									lg="6"
									md="6"
									sm="7"
									cols="12"
									class="pb-0"
									id="tourPlant"
								>
									<v-autocomplete
										v-model="plant"
										label="Plant"
										:items="plants"
										:item-text="
											({ plant_description, plant_id }) =>
												`(${plant_id}) ${plant_description}`
										"
										item-value="plant_id"
										dense
										outlined
										hide-details
									/>
								</v-col>
								<v-col
									lg="3"
									md="6"
									sm="5"
									cols="12"
									class="pb-0"
									id="tourUOM"
								>
									<v-select
										:value="uom"
										:items="uoms"
										item-text="display"
										item-value="unit"
										label="Unit of Measurement"
										outlined
										dense
										hide-details
										@change="changeUoM($event)"
									/>
								</v-col>
								<v-col lg="3" cols="12" class="pb-0">
									<v-tooltip left bottom>
										<template v-slot:activator="{ on }">
											<v-btn
												:disabled="
													!plant ||
													!customer ||
													loading ||
													searchingStock ||
													stockLoading
												"
												v-on="on"
												color="#13670B"
												block
												style="color: white"
												id="tourRecommendations"
												@click="getRecommendations()"
												:loading="
													loading ||
													searchingStock ||
													stockLoading
												"
											>
												Recommendations
											</v-btn>
										</template>
										<span>Get Recommendations</span>
									</v-tooltip>
								</v-col>
							</v-row>
						</v-col>
						<v-col
							cols="12"
							sm="4"
							lg="3"
							v-if="similarBrand[0] && showCards && !loading"
						>
							<v-card
								class="my-2 mx-1"
								flat
								outlined
								:class="{
									outOfStock:
										similarBrand[0].confirmedQuantity < 1,
								}"
							>
								<v-row>
									<v-col
										cols="5"
										lg="5"
										md="12"
										sm="12"
										class="d-flex align-center"
									>
										<v-img
											:src="
												similarBrand[0]
													.image_description
													? `https://stdzafeunorthbarkeepdev.blob.core.windows.net/barkeep-items-images/${similarBrand[0].image_description}`
													: 'https://stdzafeunorthbarkeepdev.blob.core.windows.net/barkeep-items-images/no-image-bottle.jpg'
											"
											contain
											height="150px"
											width="150px"
											style="background-color: white"
											class="pt-1"
										>
										</v-img>
									</v-col>
									<v-col cols="7" lg="7" md="12" sm="12">
										<v-card-title
											class="text-body-1 font-weight-medium"
											style="
												word-break: break-word;
												font-size: 0.7em !important;
											"
										>
											{{
												similarBrand[0]
													.material_description
											}}
										</v-card-title>
										<v-card-text
											class="pa-4"
											style="bottom: 0px"
										>
											<p
												class="mb-0 text-caption"
												style="
													word-break: break-word;
													font-size: 0.75em !important;
												"
											>
												<strong>Price:</strong>
												{{
													formatCredits(
														similarBrand[0]
															.price_per_case_incl_vat
													)
												}}
												<v-tooltip bottom>
													<template
														v-slot:activator="{
															on,
														}"
													>
														<v-icon small v-on="on">
															mdi-help-circle-outline
														</v-icon>
													</template>
													<span>
														<span>
															Price per Case.
														</span>
														<br />
														<span>
															Incl. Bot, Dep and
															VAT
														</span>
													</span>
												</v-tooltip>
											</p>
											<p
												class="mb-0 text-caption"
												style="
													word-break: break-word;
													font-size: 0.75em !important;
												"
											>
												<strong>SKU:</strong>
												{{
													similarBrand[0].material_id.slice(
														similarBrand[0]
															.material_id
															.length - 7
													)
												}}.{{ uomNum[searchInfo.uom] }}
												<v-tooltip bottom>
													<template
														v-slot:activator="{
															on,
														}"
													>
														<v-btn
															@click="
																copySKU(
																	similarBrand[0]
																		.material_id,
																	uomNum[
																		searchInfo
																			.uom
																	]
																)
															"
															x-small
															icon
															v-on="on"
														>
															<v-icon small>
																mdi-content-copy
															</v-icon>
														</v-btn>
													</template>
													<span>Copy SKU</span>
												</v-tooltip>
											</p>
											<p
												class="mb-0 text-caption"
												style="
													word-break: break-word;
													font-size: 0.75em !important;
												"
											>
												<strong>Available:</strong>
												<span v-if="!stockLoading">
													{{
														fixStock(
															similarBrand[0]
																.confirmedQuantity
														)
													}}
												</span>
												<span v-else>
													&nbsp;
													<v-progress-circular
														:size="15"
														:width="1"
														indeterminate
														color="#13670B"
													/>
												</span>
											</p>
											<p
												class="mb-0 text-caption"
												style="
													word-break: break-word;
													font-size: 0.75em !important;
												"
											>
												<strong>Pack Size:</strong>
												<span>{{
													similarBrand[0]
														.pack_description
												}}</span>
											</p>
										</v-card-text>
									</v-col>
								</v-row>
							</v-card>
						</v-col>
					</v-row>
				</v-card-title>
				<v-divider style="width: 100%; margin-top: 15px" />
			</v-card>

			<div>
				<draggable
					tag="div"
					:list="stockCards"
					class="list-group"
					handle=".handle"
					@change="stockCardsReorder"
				>
					<div v-for="(e, idx) in stockCards" :key="idx">
						<template v-if="!(e.stock == 'forCustomer' && picos.length > 0)">
							<StockCards
								v-if="$data[e.stock].length || loading"
								:title="createTitle(e.title)"
								:subtitle="createSubtitle(e.subtitle)"
								:uom="searchInfo.uom"
								:stock="$data[e.stock]"
								:loading="loading"
								:stockLoading="stockLoading"
								:num="e.num"
								:key="e.num"
							/>
						</template>
					</div>
				</draggable>
				<template v-if="!showCards">
					<v-card
						width="100%"
						flat
						class="mx-auto text-center"
						style="
							height: 400px;
							justify-content: center;
							align-items: center;
							display: flex;
						"
					>
						<div class="d-flex flex-column mb-8">
							<span class="text-h5"> Search </span>
							<span class="grey--text lighten-3">
								Enter search criteria to receive
								recommendations.
							</span>
						</div>
					</v-card>
				</template>
			</div>
		</div>
		<v-snackbar
			v-model="snackbar.show"
			:color="snackbar.color"
			timeout="2000"
		>
			{{ snackbar.text }}
			<template v-slot:action="{ attrs }">
				<v-btn icon v-bind="attrs" @click="snackbar.show = false">
					<v-icon>mdi-close-circle-outline</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</v-container>
</template>

<script>
import map from "lodash/map";
import find from "lodash/find";
import debounce from "lodash/debounce";
import cloneDeep from "lodash/cloneDeep";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import Driver from "driver.js";
import "driver.js/dist/driver.min.css";

const driver = new Driver({
	scrollIntoViewOptions: { block: "start" },
	animate: false,
	allowClose: false,
	onReset: () => {
		document
			.querySelector("#tourCustomer .v-input__slot")
			.scrollTo({ left: 0, behavior: "smooth" });
	},
});

const axios = require("axios").default;

import StockCards from "@/components/StockCards.vue";

const USERMETRICS = {
	session_customer_id: null,
	session_start_time: null,
	input_type: null,
	input_id: null,
	interactedwith_carousel: null,
	interactedwith_id: null,
	interactedwith_time: null,
	user: null,
};

export default {
	name: "RecommendationsView",
	data() {
		return {
			segment: null,
			customer: null,
			searchCustomers: "",
			customersLoading: false,
			customers: [],
			material: {},
			plant: null,
			uoms: [
				{ unit: "CS", name: "Case", display: "(CS) Case" },
				{ unit: "PC", name: "Piece", display: "(PC) Piece" },
				{ unit: "PAL", name: "Pallet", display: "(PAL) Pallet" },
			],
			uomNum: {
				CS: 0,
				PC: 1,
				PAL: 2,
			},
			carousels: [
				"promotional_items",
				"picos",
				"for_customer",
				"similar_category",
				"beer_carousel",
				"similar_brand",
			],
			searchInfo: {},
			promises: [],
			promotions: [],
			picos: [],
			forCustomer: [],
			similarBrand: [],
			similarCategory: [],
			showCards: false,
			loading: false,
			userMetrics: cloneDeep(USERMETRICS),
			snackbar: {
				show: false,
				text: "Stock refreshed!",
				color: "success",
			},
			navbarHeight: 0,
			stockLoading: false,
			searchingStock: false,
			stockSearch: null,
			stockCards: [
				{
					title: "Popular Products",
					stock: "promotions",
					num: "1",
				},
				{
					title: "PICOS Products",
					subtitle: "picos",
					stock: "picos",
					num: "2",
				},
				{
					title: "For Customer",
					subtitle: "customer",
					stock: "forCustomer",
					num: "3",
				},
				{
					title: "More Stats Group",
					subtitle: "material",
					stock: "similarCategory",
					num: "4",
				},
				{
					title: "More Brand",
					subtitle: "material",
					stock: "similarBrand",
					num: "5",
				},
			],
			creditSearch: null,
			creditCurrency: null,
			creditInfo: null,
			searchingCredit: false,
		};
	},
	created() {
		// await this.$store.dispatch("makeLookups");
		this.$eventBus.$on("copied-sku", (item) => {
			this.sendMetricsToCosmos(item, "copy");
		});
		this.$eventBus.$on("start-tour", () => {
			this.startTour();
		});
		this.$eventBus.$on("search-material", ({ material, carousel, sku }) => {
			this.material = material;
			this.stockSearch = null;
			this.sendMetricsToCosmos(
				{ material, carousel, sku: sku },
				"populate"
			);
			this.snackbar.text = "Search Material Populated";
			this.snackbar.color = "success";
			this.snackbar.show = true;
		});
	},
	mounted() {
		if (document.getElementById("navbar"))
			this.navbarHeight = document.getElementById("navbar").offsetHeight;

		let allowed = this.stockCards.map(({ title }) => title);
		if (window.localStorage.getItem("stockCardsOrder")) {
			let order = window.localStorage.getItem("stockCardsOrder");
			order = order.split(",");
			if (
				order.every((elem) => allowed.includes(elem)) &&
				order.length == allowed.length
			) {
				this.stockCards.sort(
					(a, b) => order.indexOf(a.title) - order.indexOf(b.title)
				);
			} else {
				window.localStorage.setItem("stockCardsOrder", allowed);
			}
		} else {
			window.localStorage.setItem("stockCardsOrder", allowed);
		}
		this.$nextTick(() => {
			driver.defineSteps([
				{
					element: "#tourCustomer .v-input__slot",
					popover: {
						title: "Select Customer",
						description:
							"Select a customer you want recommendations for",
					},
				},
				{
					element: "#tourMaterial .v-input__slot",
					popover: {
						title: "Select Material (Optional)",
						description: "Select a material",
					},
				},
				{
					element: "#tourStockSearch",
					popover: {
						title: "Stock Search",
						description:
							"Conduct a stock search for the selected material at the specified plant",
						position: "bottom-right",
					},
				},
				{
					element: "#tourPlant .v-input__slot",
					popover: {
						title: "Select Plant",
						description:
							"Select a plant. Ensure the plant you select is the same as the plant on SalesForce",
					},
				},
				{
					element: "#tourUOM .v-input__slot",
					popover: {
						title: "Select Unit of Measurement",
						description: "Select the required unit of measurement",
					},
				},
				{
					element: "#tourRecommendations",
					popover: {
						title: "Get Recommendations",
						description:
							"Returns recommendations for the selected customer and material",
					},
				},
				{
					element: "#tourFeedback",
					popover: {
						title: "Give Us Feedback",
						description:
							"To log suggestions/comments/feedback, click this button and complete the form",
						position: "top-right",
					},
				},
				{
					element: "#tourStockSearchScreen",
					popover: {
						title: "Stock Search",
						description:
							"Click this button if you want to navigate to the stock search screen",
					},
				},
				{
					element: "#tourSignout",
					popover: {
						title: "Sign Out",
						description: "If you want to sign out, click here",
					},
				},
				{
					element: "#tourHelp",
					popover: {
						title: "Need Help?",
						description:
							"If you need any help with Barkeep, click this button to get a tour of how to use Barkeep",
						position: "bottom-right",
					},
				},
			]);
			setTimeout(() => {
				if (!window.localStorage.getItem("barkeepTour") * 1) {
					window.localStorage.setItem("barkeepTour", 1);
					this.startTour();
				}
			}, 500);
		});
	},
	computed: {
		...mapGetters(["plants", "uom", "user", "materials"]),
		noCustomerData() {
			if (!this.searchCustomers)
				return "Enter customer name or ID to search";
			else {
				return this.customersLoading
					? "Searching for customers"
					: "No data available";
			}
		},
		searchText() {
			const uom = this.uoms.find((u) => u.unit === this.uom)?.name;
			const plant = this.plants.find(
				(p) => p.plant_id === this.plant
			)?.plant_description;
			return this.searchCustomers
				? this.searchCustomers +
						" - " +
						(this.material?.material_description
							? this.material?.material_description + " - "
							: "") +
						plant +
						" - " +
						uom
				: "";
		},
	},
	methods: {
		startTour() {
			driver.start(0);
		},
		changeUoM(ev) {
			this.$store.dispatch("setUnitOfMeasurement", ev);
		},
		formatCredits(price) {
			const formatter = new Intl.NumberFormat("en-ZA", {
				style: "currency",
				currency: "ZAR",
			});
			return formatter.format(price || 0);
		},
		createTitle(title) {
			if (title == "More Brand")
				return `More ${this.searchInfo["brand_description"]}`;
			else if (title == "More Stats Group")
				return `More ${this.searchInfo["stats_group_description"]}`;
			else if (title == "Beers") {
				return `More ${
					this.searchInfo["stats_group_id"] == "2"
						? "Beer"
						: "Beer - Craft"
				}`;
			}
			return title;
		},
		createSubtitle(subject) {
			if (!subject) return null;
			const uom = this.uoms.find(
				(u) => u.unit === this.searchInfo.uom
			)?.name;
			const plant = this.plants.find(
				(p) => p.plant_id === this.searchInfo.plant
			)?.plant_description;
			const item = subject == "picos" ? this.segment : this.searchInfo[`${subject}_description`];

			return `${item} - ${plant} - ${uom}`;
		},
		stockCardsReorder() {
			// save new list in localstorage
			let order = this.stockCards.map(({ title }) => title);
			window.localStorage.setItem("stockCardsOrder", order);
		},
		fixStock(stock) {
			return parseInt(stock ?? 0);
		},
		copySKU(SKU, uom) {
			let x = SKU.slice(SKU.length - 7) + "." + uom;
			navigator.clipboard.writeText(x);
			this.debounchCopySKU(x, this.num);
		},
		async sendMetricsToCosmos(item, activity_type) {
			if (!this.userMetrics.session_customer_id) return;

			let input_type, input_id;
			if (item.carousel === 1) {
				input_type = "promotions";
			} else {
				input_type = item.carousel === 2 ? "customer" : "material";
				input_id =
					item.carousel === 2
						? this.searchInfo["customer"]
						: this.searchInfo["material"];
			}

			const data = {
				session_customer_id: this.userMetrics.session_customer_id,
				session_start_time: this.userMetrics.session_start_time,
				input_type,
				input_id,
				interactedwith_carousel: this.carousels[item.carousel - 1],
				interactedwith_id: item.sku,
				interactedwith_time: new Date().toJSON(),
				user_email: this.user.username,
				activity_type,
			};

			try {
				await axios.post("/api/activities", data);
			} catch (error) {
				console.error(error);
			}
		},
		async searchStock(ev) {
			if (!ev?.material_id || !this.plant || !this.uom) {
				this.stockSearch = null;
				return;
			}
			try {
				this.searchingStock = true;

				const res = await axios.post("/api/stock", {
					item_list: [ev.material_id],
					plant_id: this.plant,
					uom_id: this.uom,
				});

				this.searchingStock = false;
				this.stockSearch = parseInt(
					res.data[0].confirmedQuantity ?? 0
				).toString();

				axios.post("/api/activities", {
					session_customer_id: this.userMetrics.session_customer_id,
					session_start_time: this.userMetrics.session_start_time,
					input_type: "material",
					input_id: ev.material_id,
					interactedwith_carousel: null,
					interactedwith_id: null,
					interactedwith_time: new Date().toJSON(),
					user_email: this.user.username,
					activity_type: "stock_search",
				});
			} catch (err) {
				this.searchingStock = false;
				this.snackbar.text =
					"Something went wrong! Please refresh the page.";
				this.snackbar.color = "error";
				this.snackbar.show = true;
				console.error(err);
			}
		},
		async refreshStock() {
			try {
				let url = "/api/stock";

				this.stockLoading = true;

				const updateStock = async (array) => {
					if (!array.length) return [];
					let products = map(array, "material_id");
					const response = await axios.post(url, {
						item_list: products,
						plant_id: this.plant,
						uom_id: this.uom,
					});
					return array.map((e) => {
						const confirmedQuantity = find(response.data, {
							itemId: e.material_id,
						}).confirmedQuantity;
						return { ...e, confirmedQuantity };
					});
				};

				const [
					picosData,
					forCustomerData,
					similarBrandData,
					similarCategoryData,
					promotionsData,
				] = await Promise.all([
					updateStock(this.picos),
					updateStock(this.forCustomer),
					updateStock(this.similarBrand),
					updateStock(this.similarCategory),
					updateStock(this.promotions),
				]);

				this.picos = picosData;
				console.log('picos', this.picos)
				this.forCustomer = forCustomerData;
				console.log('forcustomer', this.forCustomer)
				this.similarBrand = similarBrandData;
				this.similarCategory = similarCategoryData;
				this.promotions = promotionsData;

				this.searchInfo["uom"] = this.uom;
				this.searchInfo["plant"] = this.plant;
				this.snackbar.text = "Stock refreshed!";
				this.snackbar.color = "success";
			} catch (err) {
				this.snackbar.text =
					"Something went wrong! Please refresh the page.";
				this.snackbar.color = "error";
				console.error(err);
			} finally {
				this.stockLoading = false;
				this.snackbar.show = true;
			}
		},
		async updatePlantAndCreditInfo() {
			if (!this.customer) {
				this.creditSearch = null;
				return;
			}

			this.userMetrics.session_customer_id = this.customer;
			this.userMetrics.session_start_time = new Date().toJSON();

			const customer = find(this.customers, {
				customer_id: this.customer,
			});
			this.plant =
				customer?.plant_id != "0000" ? customer?.plant_id : null;

			// Temporarily removing credits
			// if (!customer.include_credits) {
			// 	this.creditSearch = null;
			// 	return;
			// }

			// this.creditSearch = "Credit Available:";
			// this.creditCurrency = null;

			// try {
			// 	this.searchingCredit = true;

			// 	const { data } = await axios.get(
			// 		`/api/credits?customer=${this.customer}`,
			// 		{ timeout: 20000 }
			// 	);
			// 	this.creditInfo = { ...data };

			// 	this.creditInfo.LastPaymentAmount = this.formatCredits(
			// 		this.creditInfo?.LastPaymentAmount || 0
			// 	);
			// 	this.creditInfo.LastPaymentDate =
			// 		this.creditInfo?.LastPaymentDate &&
			// 		new Date(
			// 			this.creditInfo?.LastPaymentDate.split(/[()]/)[1] * 1
			// 		)
			// 			.toString()
			// 			.slice(0, 15);

			// 	const creditAvailable =
			// 		this.creditInfo.CreditBlock === "X"
			// 			? "Blocked"
			// 			: this.formatCredits(this.creditInfo.AvailableToBuy);
			// 	this.creditSearch = `<span>Credit Available: ${creditAvailable}</span> &emsp; <span>Credit Limit: ${this.formatCredits(
			// 		this.creditInfo.CreditLimit
			// 	)}</span> &nbsp;`;
			// 	this.creditCurrency = this.creditInfo.Currency;
			// } catch (error) {
			// 	console.error(error);
			// } finally {
			// 	this.searchingCredit = false;
			// }
		},
		async getRecommendations() {
			this.promises = [];
			let url = "/api/recommendations";
			if (this.customer && this.customer != this.searchInfo.customer) {
				this.promotions = [];
				this.forCustomer = [];
				this.picos = [];
				this.promises.push(
					axios
						.get(
							`/api/promotions?plant_id=${this.plant}&uom_id=${this.uom}`
						)
						.then((res) => {
							this.promotions = cloneDeep(res.data).filter(
								(e) => e.material_id !== null
							);
						})
						.catch((err) => {
							console.log(err);
							return Promise.reject(err);
						})
				);
				this.promises.push(
					axios
						.get(
							`${url}?recommendation_type=picos&plant_id=${this.plant}&uom_id=${this.uom}&user_id=${this.customer}&num_results=20`
						)
						.then((res) => {
							this.picos = cloneDeep(res.data).filter(
								(e) => e.material_id !== null
							);
						})
						.catch((err) => {
							this.searchInfo.customer = null;
							return Promise.reject(err);
						})
				);
				this.promises.push(
					axios
						.get(
							`${url}?recommendation_type=user_personalization&plant_id=${this.plant}&uom_id=${this.uom}&user_id=${this.customer}&num_results=20`
						)
						.then((res) => {
							this.forCustomer = cloneDeep(res.data).filter(
								(e) => e.material_id !== null
							);
						})
						.catch((err) => {
							this.searchInfo.customer = null;
							return Promise.reject(err);
						})
				);
				axios
					.post("/api/activities", {
						session_customer_id:
							this.userMetrics.session_customer_id,
						session_start_time: this.userMetrics.session_start_time,
						input_type: "customer",
						input_id: this.userMetrics.session_customer_id,
						interactedwith_carousel: null,
						interactedwith_id: null,
						interactedwith_time: new Date().toJSON(),
						user_email: this.user.username,
						activity_type: "search",
					})
					.catch((err) => {
						console.log(err);
					});
			}
			if (
				this.material?.material_id &&
				this.material?.material_id != this.searchInfo.material
			) {
				this.similarBrand = [];
				this.similarCategory = [];
				this.userMetrics.material_id = this.material?.material_id;
				this.promises.push(
					axios
						.get(
							`${url}?recommendation_type=similar_items&plant_id=${this.plant}&uom_id=${this.uom}&material_id=${this.material.material_id}&user_id=${this.customer}&num_results=20&filter_type=statsgroup&filter_values=${this.material.stats_group_id}`
						)
						.then((res) => {
							this.similarCategory = cloneDeep(res.data).filter(
								(e) => e.material_id !== null
							);
						})
						.catch((err) => {
							this.searchInfo.material = null;
							return Promise.reject(err);
						})
				);
				this.promises.push(
					axios
						.get(
							`${url}?recommendation_type=similar_items&plant_id=${this.plant}&uom_id=${this.uom}&material_id=${this.material.material_id}&user_id=${this.customer}&num_results=20&filter_type=brand&filter_values=${this.material.brand_id}`
						)
						.then((res) => {
							this.similarBrand = cloneDeep(res.data).filter(
								(e) => e.material_id !== null
							);
						})
						.catch((err) => {
							this.searchInfo.material = null;
							return Promise.reject(err);
						})
				);
				axios
					.post("/api/activities", {
						session_customer_id:
							this.userMetrics.session_customer_id,
						session_start_time: this.userMetrics.session_start_time,
						input_type: "material",
						input_id: this.userMetrics.material_id,
						interactedwith_carousel: null,
						interactedwith_id: null,
						interactedwith_time: new Date().toJSON(),
						user_email: this.user.username,
						activity_type: "search",
					})
					.catch((err) => {
						console.log(err);
					});
			} else if (!this.material?.material_id) {
				this.similarBrand = [];
				this.similarCategory = [];
			}
			if (this.promises.length) {
				this.showCards = true;
				this.loading = true;
			}
			Promise.all(this.promises)
				.then(() => {
					if (
						(this.searchInfo["customer"] &&
							this.customer == this.searchInfo["customer"]) ||
						(this.searchInfo["material"] &&
							this.material?.material_id ==
								this.searchInfo["material"])
					) {
						this.refreshStock();
					}
					this.searchInfo["uom"] = this.uom;
					this.searchInfo["plant"] = this.plant;
					if (this.customer) {
						this.searchInfo["customer"] = this.customer;
						this.searchInfo["customer_description"] = find(
							this.customers,
							{
								customer_id: this.customer,
							}
						)?.customer_description;
					}
					if (this.material?.material_id) {
						this.searchInfo["material"] =
							this.material?.material_id;
						this.searchInfo["material_description"] =
							this.material?.material_description;
						this.searchInfo["brand_description"] =
							this.material?.brand_description;
						this.searchInfo["stats_group_description"] =
							this.material?.stats_group_description;
						this.searchInfo["stats_group_id"] =
							this.material?.stats_group_id;
					}
				})
				.catch((error) => {
					this.showCards = false;
					this.snackbar.text =
						"Something went wrong! Please refresh the page.";
					this.snackbar.color = "error";
					this.snackbar.show = true;
					console.log(error);
				})
				.finally(() => {
					let list = document.querySelectorAll(".swiper");
					list.forEach((e) => e.swiper.slideTo(0));
					this.loading = false;
				});
		},
		debounceCustomers: debounce(async function (search) {
			const sanitizedInput = search
				.trim()
				.toLowerCase()
				.replace(/&/g, "&amp;")
				.replace(/</g, "&lt;")
				.replace(/"/g, "&quot;");

			try {
				this.customersLoading = true;
				const { data } = await axios.get(
					`/api/meta?search_type=customers&search=${sanitizedInput}`
				);
				this.customers = cloneDeep(data);
				this.customersLoading = false;
			} catch (error) {
				this.customersLoading = false;
				console.error(error);
			}
		}, 700),
		searchMaterial(m) {
			this.$eventBus.$emit("search-material", m);
		},
	},
	watch: {
		"$vuetify.breakpoint.width"() {
			this.navbarHeight = document.getElementById("navbar").offsetHeight;
		},
		searchCustomers(v, o) {
			let x = null;
			if (this.customer)
				x = find(this.customers, {
					customer_id: this.customer,
				})?.display;
			if (v && v != o && v != x) {
				this.debounceCustomers(v);
			}
		},
		// eslint-disable-next-line
		customer: function (newCustomer, oldCustomer){
			if (!newCustomer) {
				this.segment = null
			} else {
				this.segment = this.customers.find( e => e.customer_id === newCustomer).segment
			}
		}
	},
	components: {
		StockCards,
		draggable,
	},
};
</script>

<style>
.v-text-field__details {
	padding: 0px !important;
}

.recommendation-container {
	width: 85%;
}

@media only screen and (max-width: 1600px) {
	.recommendation-container {
		width: 90%;
	}
}

@media only screen and (max-width: 1500px) {
	.recommendation-container {
		width: 95%;
	}
}

@media only screen and (max-width: 1200px) {
	.v-select__slot {
		font-size: 14px !important;
	}
	.search-text {
		font-size: 0.9rem;
	}
	.recommendation-container {
		width: 100%;
	}
}
@media only screen and (max-width: 900px) {
	.v-select__slot {
		font-size: 13px !important;
	}
}
@media only screen and (max-width: 850px) {
	.search-text {
		font-size: 0.75rem;
	}
}
@media only screen and (max-width: 750px) {
	.v-select__slot {
		font-size: 12px !important;
	}
}
</style>
